/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Component, OnInit } from '@angular/core';

import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Bearertoken } from '@shared/authentication/model/bearer-token-model';
import { DetailsViewService, FileManagerModule, NavigationPaneService, ToolbarService } from '@syncfusion/ej2-angular-filemanager';
import { BaseComponentDirective } from 'core/component/base.component';
import { TitleService } from 'core/service/title.service';
import { AuthenticationService } from './authentication/services/authentication.service';
import { LoadingService } from './shared/services/loading-service';

@Component({
    selector: 'app-msis',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
providers:[NavigationPaneService, ToolbarService, DetailsViewService, FileManagerModule],
})
export class AppComponent extends BaseComponentDirective implements OnInit {
    user: Bearertoken;
    items;
    userauthentication: boolean;
    showHeader = true;
    loading = true;
    hostUrl: string;
    
    constructor(private authenticationService: AuthenticationService, private readonly router: Router, private readonly pageTitleService: TitleService, public loadingTest: LoadingService) {
        super();
        this.authenticationService.user.subscribe((x) => {
            this.user = x;
        });

        void this.router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.userauthentication = this.authenticationService.isUserLoggedIn;
            }
        });
        router.events.subscribe((routerEvent: Event) => {
            this.checkRouterEvent(routerEvent);
        });
        if (window.location.href.indexOf('user-registration') > -1) {
            this.showHeader = false;
        }
    }

    logout(): void {
        this.authenticationService.logout();
    }

    // get isAdmin() {
    //     return this.authenticationService.isAdmin;
    // }

    // get isSuperAdmin() {
    //     return this.authenticationService.isSuperAdmin;
    // }

    ngOnInit(): void {
        super.ngOnInit();
        this.pageTitleService.init();
        
        // Navigation Pane settings customization
    }

    checkRouterEvent(routerEvent: Event): void {
        if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('dialog-open');
        }
    }
}
